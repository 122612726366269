

export default [
    {
        url: require('../images/dog_background_1.jpg'),
        name: "Engedelmes",
        address: "Törökbálint, Tó utca",
        place: "Törökbálinti Kutyaiskola",
        description: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit ",
        time: "Szombat, 10:00 óra",
        price: "2000 Ft",
    },
    {
        url: require('../images/dog_background_2.jpg'),
        name: "Agility",
        address: "Törökbálint, Tó utca",
        place: "Törökbálinti Kutyaiskola",
        description: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit ",
        time: "Szombat, 10:00 óra",
        price: "2000 Ft",
    },
    {
        url: require('../images/dog_background_3.jpg'),
        name: "Mantrailing",
        address: "Törökbálint, Tó utca",
        place: "Törökbálinti Kutyaiskola",
        description: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit ",
        time: "Szombat, 10:00 óra",
        price: "2000 Ft",
    },
    {
        url: require('../images/dog_background_4.jpg'),
        name: "Trükk",
        address: "Törökbálint, Tó utca",
        place: "Törökbálinti Kutyaiskola",
        description: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit ",
        time: "Szombat, 10:00 óra",
        price: "2000 Ft",
    },
    {
        url: require('../images/dog_background_5.jpg'),
        name: "Őrző-Védő",
        address: "Törökbálint, Tó utca",
        place: "Törökbálinti Kutyaiskola",
        description: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit ",
        time: "Szombat, 10:00 óra",
        price: "2000 Ft",
    },
];