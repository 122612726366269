const nyomkovetes = [
    {
        id: 1,
        dog_img: require('../../images/nyomkovetes/IMG_20240401_111454.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/nyomkovetes/IMG_20240421_155251_1.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/nyomkovetes/IMG_20240421_155652.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/nyomkovetes/IMG_20240421_155814.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/nyomkovetes/IMG_20240421_155905.jpg')
    },
    {
        id: 6,
        dog_img: require('../../images/nyomkovetes/IMG_20240421_160628.jpg')
    },
    {
        id: 7,
        dog_img: require('../../images/nyomkovetes/IMG_20240421_164656.jpg')
    },
    {
        id: 8,
        dog_img: require('../../images/nyomkovetes/IMG_20240421_164913.jpg')
    },
    {
        id: 9,
        dog_img: require('../../images/nyomkovetes/IMG_20240421_165022.jpg')
    },
    {
        id: 10,
        dog_img: require('../../images/nyomkovetes/IMG_20240421_174539.jpg')
    },
    {
        id: 11,
        dog_img: require('../../images/nyomkovetes/IMG_20240421_174600.jpg')
    }
];

export default nyomkovetes;
