const orzoVedo = [
    {
        id: 1,
        dog_img: require('../../images/orzoVedo/IMG_20230916_110743_1.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/orzoVedo/IMG_20240127_171936.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/orzoVedo/IMG_20240225_113456.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/orzoVedo/IMG_20240225_113618_2.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/orzoVedo/IMG_20240225_114526_1.jpg')
    },
    {
        id: 6,
        dog_img: require('../../images/orzoVedo/received_300433009263270.jpg')
    },
    {
        id: 7,
        dog_img: require('../../images/orzoVedo/received_1353546632037301.jpg')
    }
];

export default orzoVedo;
