const napkozi = [
    {
        id: 1,
        dog_img: require('../../images/napkozi/FB_IMG_1712123005124.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/napkozi/FB_IMG_1712123232551.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/napkozi/FB_IMG_1712123238927.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/napkozi/IMG_20240117_102758.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/napkozi/IMG_20240117_102905_1.jpg')
    },
    {
        id: 6,
        dog_img: require('../../images/napkozi/IMG_20240117_143952_1.jpg')
    },
    {
        id: 7,
        dog_img: require('../../images/napkozi/IMG_20240117_150140.jpg')
    },
    {
        id: 8,
        dog_img: require('../../images/napkozi/IMG_20240213_161934.jpg')
    },
    {
        id: 9,
        dog_img: require('../../images/napkozi/IMG_20240305_145108_1.jpg')
    },
    {
        id: 10,
        dog_img: require('../../images/napkozi/IMG_20240311_091850_2.jpg')
    },
    {
        id: 11,
        dog_img: require('../../images/napkozi/IMG_20240311_092412_1.jpg')
    },
    {
        id: 12,
        dog_img: require('../../images/napkozi/IMG_20240321_170942_1.jpg')
    },
    {
        id: 13,
        dog_img: require('../../images/napkozi/IMG_20240328_084146 - Copy.jpg')
    },
    {
        id: 14,
        dog_img: require('../../images/napkozi/IMG_20240328_084146.jpg')
    },
    {
        id: 15,
        dog_img: require('../../images/napkozi/IMG_20240403_064830.jpg')
    },
    {
        id: 16,
        dog_img: require('../../images/napkozi/IMG_20240404_143543_1.jpg')
    },
    {
        id: 17,
        dog_img: require('../../images/napkozi/IMG_20240404_143804_1.jpg')
    },
    {
        id: 18,
        dog_img: require('../../images/napkozi/IMG_20240404_143836.jpg')
    },
    {
        id: 19,
        dog_img: require('../../images/napkozi/IMG_20240404_143839_1.jpg')
    },
    {
        id: 20,
        dog_img: require('../../images/napkozi/IMG_20240404_144541_1.jpg')
    },
    {
        id: 21,
        dog_img: require('../../images/napkozi/IMG_20240404_145124.jpg')
    },
    {
        id: 22,
        dog_img: require('../../images/napkozi/IMG_20240404_145327_1.jpg')
    },
    {
        id: 23,
        dog_img: require('../../images/napkozi/IMG_20240404_145622_1.jpg')
    },
    {
        id: 24,
        dog_img: require('../../images/napkozi/IMG_20240404_151151.jpg')
    },
    {
        id: 25,
        dog_img: require('../../images/napkozi/IMG_20240404_151301.jpg')
    },
    {
        id: 26,
        dog_img: require('../../images/napkozi/IMG_20240411_120452_1.jpg')
    },
    {
        id: 27,
        dog_img: require('../../images/napkozi/IMG_20240411_120629_1.jpg')
    },
    {
        id: 28,
        dog_img: require('../../images/napkozi/IMG_20240411_120816_1.jpg')
    },
    {
        id: 29,
        dog_img: require('../../images/napkozi/IMG_20240411_130407.jpg')
    }
];

export default napkozi;
