const egyeniTrening = [
    {
        id: 1,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123042068.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123044862.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123393944.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123402500.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123405709.jpg')
    },
    {
        id: 6,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123432200.jpg')
    },
    {
        id: 7,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123437661.jpg')
    },
    {
        id: 8,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123487560.jpg')
    },
    {
        id: 9,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123498641.jpg')
    },
    {
        id: 10,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123525041.jpg')
    },
    {
        id: 11,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123546838.jpg')
    },
    {
        id: 12,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123549751.jpg')
    },
    {
        id: 13,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123559801.jpg')
    },
    {
        id: 14,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123564034.jpg')
    },
    {
        id: 15,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123617932.jpg')
    },
    {
        id: 16,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123623964.jpg')
    },
    {
        id: 17,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123627815.jpg')
    },
    {
        id: 18,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123631493.jpg')
    },
    {
        id: 19,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123670511.jpg')
    },
    {
        id: 20,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123683835.jpg')
    },
    {
        id: 21,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123709368.jpg')
    },
    {
        id: 22,
        dog_img: require('../../images/egyeni_trening/FB_IMG_1712123718480.jpg')
    },
    {
        id: 23,
        dog_img: require('../../images/egyeni_trening/IMG_20240403_151250.jpg')
    }
];

export default egyeniTrening;
