const ugyessegi = [
    {
        id: 1,
        dog_img: require('../../images/ugyessegi/IMG_20240421_104636_1.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/ugyessegi/IMG_20240421_114139.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/ugyessegi/IMG_20240421_114332.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/ugyessegi/IMG_20240421_114421.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/ugyessegi/IMG_20240421_114552.jpg')
    },
    {
        id: 6,
        dog_img: require('../../images/ugyessegi/IMG_20240421_114709.jpg')
    }
    
]

export default ugyessegi;