const szolgalati = [
    {
        id: 1,
        dog_img: require('../../images/szolgalati/received_609939141058585.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/szolgalati/FB_IMG_1712122930117.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/szolgalati/FB_IMG_1712122925404.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/szolgalati/FB_IMG_1712122922401.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/szolgalati/FB_IMG_1707490277883.jpg')
    },
    {
        id: 6,
        dog_img: require('../../images/szolgalati/FB_IMG_1707490362003.jpg')
    },
    {
        id: 7,
        dog_img: require('../../images/szolgalati/FB_IMG_1707490335371.jpg')
    },
    {
        id: 8,
        dog_img: require('../../images/szolgalati/FB_IMG_1707490431152.jpg')
    },
    {
        id: 9,
        dog_img: require('../../images/szolgalati/FB_IMG_1707490092261.jpg')
    },
    {
        id: 10,
        dog_img: require('../../images/szolgalati/FB_IMG_1707490237443.jpg')
    }
];

export default szolgalati;
