const kolyok_szoc = [
    {
        id: 1,
        dog_img: require('../../images/kolyok_szoc/IMG_20240404_144830_1.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/kolyok_szoc/IMG_20240404_150444.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/kolyok_szoc/IMG_20240404_150526.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/kolyok_szoc/IMG_20240405_093451_1.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/kolyok_szoc/IMG_20230916_120033_1.jpg')
    },
];

export default kolyok_szoc;
