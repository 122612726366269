const bemutatok = [
    {
        id: 1,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150682016.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150703371.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150710011.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150731189.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150739365.jpg')
    },
    {
        id: 6,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150755366.jpg')
    },
    {
        id: 7,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150776953.jpg')
    },
    {
        id: 8,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150798057.jpg')
    },
    {
        id: 9,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150806508.jpg')
    },
    {
        id: 10,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150811855.jpg')
    },
    {
        id: 11,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150816397.jpg')
    },
    {
        id: 12,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150827654.jpg')
    },
    {
        id: 13,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150842751.jpg')
    },
    {
        id: 14,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150845692.jpg')
    },
    {
        id: 15,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150897021.jpg')
    },
    {
        id: 16,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150900672.jpg')
    },
    {
        id: 17,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150905429.jpg')
    },
    {
        id: 18,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150910341.jpg')
    },
    {
        id: 19,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150917434.jpg')
    },
    {
        id: 20,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150930210.jpg')
    },
    {
        id: 21,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150942160.jpg')
    },
    {
        id: 22,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150953007.jpg')
    },
    {
        id: 23,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150958581.jpg')
    },
    {
        id: 24,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150963606.jpg')
    },
    {
        id: 25,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150966377.jpg')
    },
    {
        id: 26,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150970405.jpg')
    },
    {
        id: 27,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150973603.jpg')
    },
    {
        id: 28,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150990866.jpg')
    },
    {
        id: 29,
        dog_img: require('../../images/bemutatok/FB_IMG_1712150997499.jpg')
    },
    {
        id: 30,
        dog_img: require('../../images/bemutatok/FB_IMG_1712151005683.jpg')
    },
    {
        id: 31,
        dog_img: require('../../images/bemutatok/FB_IMG_1712151023117.jpg')
    },
    {
        id: 32,
        dog_img: require('../../images/bemutatok/FB_IMG_1712151041091.jpg')
    },
    {
        id: 33,
        dog_img: require('../../images/bemutatok/FB_IMG_1712151073296.jpg')
    },
    {
        id: 34,
        dog_img: require('../../images/bemutatok/FB_IMG_1712151093470.jpg')
    },
    {
        id: 35,
        dog_img: require('../../images/bemutatok/FB_IMG_1712151147886.jpg')
    },
    {
        id: 36,
        dog_img: require('../../images/bemutatok/IMG_0209.jpg')
    },
    {
        id: 37,
        dog_img: require('../../images/bemutatok/IMG_0253.jpg')
    },
    {
        id: 38,
        dog_img: require('../../images/bemutatok/IMG_5640.jpg')
    },
];

export default bemutatok;