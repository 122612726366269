import "./ball.css";

const Ball = () => {
  return (
    <div className="ball_outer_container">
        <div className="ball_inner_container">
            <div className="ball">

            </div>
        </div>
    </div>
  )
}

export default Ball