const kutyaIskola = [
    {
        id: 1,
        dog_img: require('../../images/kutyaiskola/APhoto_11709203284764.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/kutyaiskola/BBPhoto_1712001931617.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/kutyaiskola/CPhoto_1711981389012.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/kutyaiskola/DPhoto_1712002990040.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/kutyaiskola/FB_IMG_1712122744666.jpg')
    },
    {
        id: 6,
        dog_img: require('../../images/kutyaiskola/FB_IMG_1712122752365.jpg')
    },
    {
        id: 7,
        dog_img: require('../../images/kutyaiskola/FB_IMG_1712122777104.jpg')
    },
    {
        id: 8,
        dog_img: require('../../images/kutyaiskola/FB_IMG_1712122847372.jpg')
    },
    {
        id: 9,
        dog_img: require('../../images/kutyaiskola/FB_IMG_1712122861222.jpg')
    },
    {
        id: 10,
        dog_img: require('../../images/kutyaiskola/FB_IMG_1712123094841.jpg')
    },
    {
        id: 11,
        dog_img: require('../../images/kutyaiskola/FB_IMG_1712123140787.jpg')
    },
    {
        id: 12,
        dog_img: require('../../images/kutyaiskola/FB_IMG_1712123160589.jpg')
    },
    {
        id: 13,
        dog_img: require('../../images/kutyaiskola/FB_IMG_1712123167428.jpg')
    },
    {
        id: 14,
        dog_img: require('../../images/kutyaiskola/FB_IMG_1712123297125.jpg')
    },
    {
        id: 15,
        dog_img: require('../../images/kutyaiskola/IMG_20230731_215138.jpg')
    },
    {
        id: 16,
        dog_img: require('../../images/kutyaiskola/IMG_20230909_094538.jpg')
    },
    {
        id: 17,
        dog_img: require('../../images/kutyaiskola/IMG_20230909_103510_1.jpg')
    },
    {
        id: 18,
        dog_img: require('../../images/kutyaiskola/IMG_20230909_120549.jpg')
    },
    {
        id: 19,
        dog_img: require('../../images/kutyaiskola/IMG_20230909_120821.jpg')
    },
    {
        id: 20,
        dog_img: require('../../images/kutyaiskola/IMG_20230916_102252.jpg')
    },
    {
        id: 21,
        dog_img: require('../../images/kutyaiskola/IMG_20230916_102955.jpg')
    },
    {
        id: 22,
        dog_img: require('../../images/kutyaiskola/IMG_20230916_103033_1.jpg')
    },
    {
        id: 23,
        dog_img: require('../../images/kutyaiskola/IMG_20230916_103150_1.jpg')
    },
    {
        id: 24,
        dog_img: require('../../images/kutyaiskola/IMG_20230916_103437_1.jpg')
    },
    {
        id: 25,
        dog_img: require('../../images/kutyaiskola/IMG_20230917_093033_1.jpg')
    },
    {
        id: 26,
        dog_img: require('../../images/kutyaiskola/IMG_20230917_100504_1.jpg')
    },
    {
        id: 27,
        dog_img: require('../../images/kutyaiskola/IMG_20230917_100518.jpg')
    },
    {
        id: 28,
        dog_img: require('../../images/kutyaiskola/IMG_20240125_114607.jpg')
    },
    {
        id: 29,
        dog_img: require('../../images/kutyaiskola/IMG_20240127_173044.jpg')
    },
    {
        id: 30,
        dog_img: require('../../images/kutyaiskola/IMG_20240127_173140.jpg')
    },
    {
        id: 31,
        dog_img: require('../../images/kutyaiskola/IMG_20240127_173229.jpg')
    },
    {
        id: 32,
        dog_img: require('../../images/kutyaiskola/IMG_20240203_152808_1.jpg')
    },
    {
        id: 33,
        dog_img: require('../../images/kutyaiskola/IMG_20240204_052803.jpg')
    },
    {
        id: 34,
        dog_img: require('../../images/kutyaiskola/IMG_20240204_092037_1.jpg')
    },
    {
        id: 35,
        dog_img: require('../../images/kutyaiskola/IMG_20240206_115822.jpg')
    },
    {
        id: 36,
        dog_img: require('../../images/kutyaiskola/IMG_20240218_094418.jpg')
    },
    {
        id: 37,
        dog_img: require('../../images/kutyaiskola/IMG_20240218_095227_1.jpg')
    },
    {
        id: 38,
        dog_img: require('../../images/kutyaiskola/IMG_20240225_103138_1.jpg')
    },
    {
        id: 39,
        dog_img: require('../../images/kutyaiskola/IMG_20240225_103252_1.jpg')
    },
    {
        id: 40,
        dog_img: require('../../images/kutyaiskola/IMG_20240225_103315.jpg')
    },
    {
        id: 41,
        dog_img: require('../../images/kutyaiskola/IMG_20240225_104013_1.jpg')
    },
    {
        id: 42,
        dog_img: require('../../images/kutyaiskola/IMG_20240401_112523.jpg')
    },
    {
        id: 43,
        dog_img: require('../../images/kutyaiskola/IMG_20240406_102025_1.jpg')
    },
    {
        id: 44,
        dog_img: require('../../images/kutyaiskola/IMG_20240406_103428_1.jpg')
    },
    {
        id: 45,
        dog_img: require('../../images/kutyaiskola/IMG_20240406_103447_1 - Copy.jpg')
    },
    {
        id: 46,
        dog_img: require('../../images/kutyaiskola/IMG_20240406_103556_1.jpg')
    },
    {
        id: 47,
        dog_img: require('../../images/kutyaiskola/IMG_20240407_083800_1.jpg')
    },
    {
        id: 48,
        dog_img: require('../../images/kutyaiskola/IMG_20240407_103229_1.jpg')
    },
    {
        id: 49,
        dog_img: require('../../images/kutyaiskola/IMG_20240407_103230_1.jpg')
    },
    {
        id: 50,
        dog_img: require('../../images/kutyaiskola/IMG_20240407_113914.jpg')
    },
    {
        id: 51,
        dog_img: require('../../images/kutyaiskola/IMG_20240407_113933.jpg')
    },
    {
        id: 52,
        dog_img: require('../../images/kutyaiskola/IMG_20240407_135954.jpg')
    },
    {
        id: 53,
        dog_img: require('../../images/kutyaiskola/IMG_20240409_141654.jpg')
    },
    {
        id: 54,
        dog_img: require('../../images/kutyaiskola/IMG_20240410_175534_1.jpg')
    }
];

export default kutyaIskola;
