const engedelmes = [
    {
        id: 1,
        dog_img: require('../../images/engedelmes/FB_IMG_1711636328978.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/engedelmes/IMG_20240217_094747_1.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/engedelmes/IMG_20240218_073935.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/engedelmes/IMG_20240228_113754.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/engedelmes/IMG_20240317_124343.jpg')
    },
    {
        id: 6,
        dog_img: require('../../images/engedelmes/IMG_20240317_131228_1.jpg')
    },
    {
        id: 7,
        dog_img: require('../../images/engedelmes/IMG_20240317_132407.jpg')
    },
    {
        id: 8,
        dog_img: require('../../images/engedelmes/IMG_20240317_132632.jpg')
    },
    {
        id: 9,
        dog_img: require('../../images/engedelmes/IMG_20240331_173638.jpg')
    },
    {
        id: 10,
        dog_img: require('../../images/engedelmes/IMG_20240420_164736_1.jpg')
    },
    {
        id: 11,
        dog_img: require('../../images/engedelmes/IMG_20240421_114908.jpg')
    },
    {
        id: 12,
        dog_img: require('../../images/engedelmes/IMG_20240421_114928.jpg')
    },
    {
        id: 13,
        dog_img: require('../../images/engedelmes/IMG_20240421_115044.jpg')
    },
];

export default engedelmes;
