const ludovika = [
    {
        id: 1,
        dog_img: require('../../images/ludovika/FB_IMG_1712122806981.jpg')
    },
    {
        id: 2,
        dog_img: require('../../images/ludovika/FB_IMG_1712122870000.jpg')
    },
    {
        id: 3,
        dog_img: require('../../images/ludovika/FB_IMG_1712123063239.jpg')
    },
    {
        id: 4,
        dog_img: require('../../images/ludovika/FB_IMG_1712123207624.jpg')
    },
    {
        id: 5,
        dog_img: require('../../images/ludovika/IMG_20240403_151759.jpg')
    },
    {
        id: 6,
        dog_img: require('../../images/ludovika/csoportkep.jpg')
    },
    {
        id: 7,
        dog_img: require('../../images/ludovika/IMG_20240422_193312.jpg')
    },
];

export default ludovika;
